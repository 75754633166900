import { initializeApp, getApps, getApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyB7H_pmJZ8bfFMQ5TfbwS3Y-s1Hc2F9Xro",
	authDomain: "project--7003778079567770323.firebaseapp.com",
	databaseURL: "https://project--7003778079567770323.firebaseio.com",
	projectId: "project--7003778079567770323",
	storageBucket: "project--7003778079567770323.appspot.com",
	messagingSenderId: "428388859947",
	appId: "1:428388859947:web:19815fe8a15c66c3872a6e",
	measurementId: "G-13KSB3HJQS",
};

// Initialize Firebase
const initFirebase = () => {
	if (!getApps().length) {
		return initializeApp(firebaseConfig);
	}
	return getApp(); // if already initialized, use that one
};

const app = typeof window !== 'undefined' ? initFirebase() : null;
const database = typeof window !== 'undefined' ? getDatabase(app) : null;
const analytics = typeof window !== 'undefined' && app ? getAnalytics(app) : null;

export { firebaseConfig, app, database, analytics };
