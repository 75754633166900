import React from "react";
import { hydrate } from "react-dom";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import reducers from "./redux";
import { app, analytics } from "./utils/db";

import Routes from "./mainRoutes";
import { HelmetProvider } from "react-helmet-async";
import { logEvent } from "firebase/analytics";

const hasWindow = typeof window !== "undefined";

if (hasWindow && analytics) {
	logEvent(analytics, "Load_Cssline_App", { time: new Date().toString() });
	// if (window.gtag && typeof window.gtag === "function") {
	// 	window.dataLayer = window.dataLayer || [];
	// 	gtag("js", new Date());
	// 	gtag("config", firebaseConfig.measurementId);
	// }
}

const composeEnhancers =
	(process.env.NODE_ENV !== "production" && hasWindow && window.top["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
	compose;

const history = createBrowserHistory();

const middleWares = [routerMiddleware(history)];

const store = createStore(reducers(history), window["wdso"] || {}, composeEnhancers(applyMiddleware(...middleWares)));

hydrate(
	<Provider store={store}>
		<HelmetProvider>
			<ConnectedRouter history={history}>
				<Routes />
			</ConnectedRouter>
		</HelmetProvider>
	</Provider>,
	document.getElementById("root")
);

if (module.hot) {
	module.hot.accept();
}
